import { ComponentType, PropsWithChildren } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { isTenant } from '@/utils/config'

import { DEFAULT_SEO_META } from './constants'
import { SeoProps } from '@/data/types'

export interface SeoComponentProps {
  seoTags?: SeoProps | null | undefined
}

const DefaultSeo = () => {
  let title = DEFAULT_SEO_META.tk.title
  let description = DEFAULT_SEO_META.tk.description

  if (isTenant('ww')) {
    title = DEFAULT_SEO_META.ww.title
    description = DEFAULT_SEO_META.ww.description
  }

  return (
    <Head>
      <title> {title} </title>
      <meta name="title" content={title} />
      {<meta name="description" content={description} />}
    </Head>
  )
}

const Seo: ComponentType<PropsWithChildren<SeoComponentProps>> = ({ seoTags }) => {
  const router = useRouter()

  if (!seoTags) return <DefaultSeo />

  const { title, titleTag, description, abstract, canonical, canonicalURL, robots } = seoTags
  return (
    <Head>
      <title>{titleTag || title}</title>
      <meta name="title" content={title} />
      {robots && <meta name="robots" content={robots} />}
      {description && <meta name="description" content={description} />}
      {abstract && <meta name="abstract" content={abstract} />}
      {canonical && (
        <link
          rel="canonical"
          href={`${process.env.APP_PAPER_HOST}${router.basePath}${canonicalURL || router.asPath.split('?')[0]}`}
        />
      )}
    </Head>
  )
}

export default Seo
