import { ComponentType, MouseEvent, PropsWithChildren } from 'react'
import Link from 'next/link'

import { Category, Collection } from '@/data/types'
import router from 'next/router'
export interface CategoryLinkProps {
  subnavLinkData: SubnavLinkData
  onClick?: (e: MouseEvent, data: SubnavLinkData) => void
  isCollection?: boolean
}

// export type SubnavLinkData = Category | Collection
export type SubnavLinkData = {
  id?: number
  sku?: string
  name: string
  href: string
  subCategories?: Category[]
  subCollections?: Collection[]
  parentId?: number
  parentSku?: string
  referrerId?: number
  nameTextOverride?: string
}

const CategoryLink: ComponentType<PropsWithChildren<CategoryLinkProps>> = ({ subnavLinkData, onClick }) => {
  const { name, href, nameTextOverride } = subnavLinkData
  const displayName = nameTextOverride ?? name

  const handleOnClick = (e: MouseEvent) => {
    if (onClick) {
      onClick(e, subnavLinkData)
    }
    router.push(href)
  }

  return (
    <Link href={href} passHref>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={handleOnClick} data-test="subnav-link">
        {displayName}
      </a>
    </Link>
  )
}

export default CategoryLink
