import { ImageTKWW } from '@/ui/ImageWrapper/ImageTKWW'
import { useState } from 'react'

import { handleZendeskOnError } from '@/utils/helpers'
import { updateSettingZendeskClassic } from '@tkww/paper-zendesk'
import CountrySelectorContent from './countrySelectorContent'
import FooterLinks from './footerLinks'
import {
  areYouVendorLinksData,
  corporativeLinksData,
  countryAmericaLinksData,
  countryAsiaLinksData,
  countryEuropeLinksData,
  downloadAppLinksData,
  goToLinksData,
  socialLinksData,
  toolsLinksData,
  utilsLinksData,
  vendorsLinksData,
} from './footerLinksData'
import styles from './styles.scss'

const date = new Date().getFullYear()

const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const onModalOpenHandler = () => {
    setModalIsOpen(true)
    updateSettingZendeskClassic('hide', undefined, (error) => handleZendeskOnError(error, 'footer-ww'))
  }
  const onModalCloseHandler = () => {
    setModalIsOpen(false)
    updateSettingZendeskClassic('show', undefined, (error) => handleZendeskOnError(error, 'footer-ww'))
  }

  return (
    <div className={styles.layoutFooter}>
      <div className={styles.linkingFooter}>
        <section className={styles.corporativeLinks}>
          <FooterLinks title="Weddingwire" data={corporativeLinksData} />
          <FooterLinks title="Find vendors" data={vendorsLinksData} />
        </section>
        <section className={styles.toolsLinks}>
          <FooterLinks title="Planning Tools" data={toolsLinksData} />
        </section>
        <section className={styles.utilsLinks}>
          <FooterLinks title="More" data={utilsLinksData} />
          <FooterLinks title="Going to a wedding?" data={goToLinksData} />
          <FooterLinks title="Are you a vendor?" data={areYouVendorLinksData} />
        </section>
        <section className={styles.appsAndSocialLinks}>
          <p className={styles.linkingFooter__title}>Download the WeddingWire App</p>
          <div className={styles.appsAndSocialLinks__description}>
            <div className={styles.appsAndSocialLinks__image}>
              <ImageTKWW
                src="https://www.weddingwire.com/mobile/assets/img/download-app@2x.png"
                alt="App icon"
                width="48"
                height="48"
              />
            </div>
            Plan your wedding anytime, anywhere
          </div>
          <ul className={styles.appsAndSocialLinks__list}>
            {downloadAppLinksData.map((link, index) => (
              <li key={index}>
                <a rel="nofollow noopener noreferrer" href={link.href} target="_blank">
                  <ImageTKWW src={link.src} alt={link.alt} width="140" height="42" />
                </a>
              </li>
            ))}
          </ul>
          <p className={styles.linkingFooter__title}>Connect with us</p>
          <ul className={styles.appsAndSocialLinks__list}>
            {socialLinksData.map((link, index) => (
              <li key={index}>
                <a
                  className={styles.appsAndSocialLinks__item}
                  rel="nofollow noopener noreferrer"
                  href={link.href}
                  title={link.title}
                  target="_blank"
                >
                  {link.svg}
                </a>
              </li>
            ))}
          </ul>
          <p className={styles.linkingFooter__title}>Choose a country</p>
          <div className={styles.countrySelector__wrapper}>
            <div className={styles.countrySelector__dropdown} onClick={onModalOpenHandler}>
              <div className={styles.countrySelector__flag}>
                <ImageTKWW
                  src={'https://www.weddingwire.com/assets/img/paper/flags/usa.png'}
                  width="18"
                  height="12"
                  alt="United States"
                />
              </div>
              United States
              <span className={styles.countrySelector__icon}>
                <svg viewBox="0 0 18 18" width="18" height="18">
                  <path
                    d="M16.9 5.6c-.2-.2-.5-.2-.7 0L9 12.8 1.8 5.6c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l7.5 7.5v.1c.1.1.3.1.4.1.1 0 .3 0 .4-.1v-.1l7.5-7.5c.2-.2.2-.5 0-.7z"
                    fill="#222222"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
          {modalIsOpen && (
            <div className={styles.modal} role="dialog">
              <div className={styles.modal__content}>
                <div className={styles.countrySelectorModal}>
                  <h1 className={styles.countrySelectorModal__title}>
                    Choose a country
                    <span className={styles.countrySelectorModal__closeIcon} onClick={onModalCloseHandler}>
                      <svg viewBox="0 0 26 26" width="26" height="26">
                        <path
                          d="M12.983 10.862L23.405.439l2.122 2.122-10.423 10.422 10.423 10.422-2.122 2.122-10.422-10.423L2.561 25.527.439 23.405l10.423-10.422L.439 2.561 2.561.439l10.422 10.423z"
                          fillRule="nonzero"
                        ></path>
                      </svg>
                    </span>
                  </h1>
                  <div className={styles.countrySelectorModal__body}>
                    <CountrySelectorContent title="Europe" data={countryEuropeLinksData} />
                    <CountrySelectorContent title="Asia" data={countryAsiaLinksData} />
                    <CountrySelectorContent title="Americas" data={countryAmericaLinksData} />
                  </div>
                </div>
              </div>
              <div className={styles.modal__overlay}></div>
            </div>
          )}
        </section>
        <section className={styles.copyrightFooter}>
          <ImageTKWW
            src="https://www.weddingwire.com/assets/img/footer/tkww_logo_KO_2x.png"
            alt="ww logo"
            width="140"
            height="48"
          />
          <p>© 2006 - {date}</p>
        </section>
      </div>
    </div>
  )
}

export default Footer
