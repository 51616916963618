import { ComponentType, PropsWithChildren } from 'react'
import styles from './styles.scss'
import { useTracking } from '@/components/Tracking/useTracking/useTracking'

interface FooterProps {
  title: string
  data: any
}

const FooterLinks: ComponentType<PropsWithChildren<FooterProps>> = ({ title, data }) => {
  const { platform } = useTracking()

  function onClick(link: { content?: string }) {
    if (link.content && typeof window !== 'undefined') {
      window.analytics.track('Footer Interaction', {
        platform: platform,
        product: 'paper',
        selection: link.content,
      })
    }
  }

  return (
    <>
      <p className={styles.linkingFooter__title}>{title}</p>
      <ul>
        {data.map((link: any, index: number) => (
          <li key={index}>
            <a href={link.href} rel={link.rel} target={link.target} onClick={link?.onClick || onClick(link)}>
              {link.content}
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}

export default FooterLinks
