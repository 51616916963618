import { useContext } from 'react'
import { FeatureToggleTypes } from '..'
import { FeatureToggleContext } from './FeatureToggleContext'

export const useFeatureToggle = () => {
  const features = useContext(FeatureToggleContext)

  if (features === undefined) {
    throw new Error('useFeatureToggle hook must be used within a FeatureToggleProvider')
  }

  const isEnabled = (feature: FeatureToggleTypes) => features?.[feature]

  return {
    features,
    isEnabled,
  }
}
