import { InlineAlert } from '@xo-union/tk-component-alerts'
import cx from 'classnames'
import { ReactNode } from 'react'
import styles from './styles.module.scss'

export interface NoticeBannerProps {
  type: 'success' | 'error' | 'warning'
  headline?: string
  children: ReactNode | string
  handleOnClose?: () => void
  styleOverrides?: Record<string, string>
  isDisplayed?: boolean
}

const NoticeBanner = ({
  type,
  headline,
  handleOnClose,
  styleOverrides,
  children,
  isDisplayed = true,
}: NoticeBannerProps) => {
  return (
    <InlineAlert
      type={type}
      headline={headline}
      onCloseButtonClick={handleOnClose}
      className={cx(styles.noticeBanner, styleOverrides?.noticeBanner)}
      visible={isDisplayed}
    >
      {children}
    </InlineAlert>
  )
}

export default NoticeBanner
