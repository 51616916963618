import FooterNav from '@xo-union/tk-component-footer-nav'
import { FooterLink } from '@xo-union/tk-component-footer-nav'
import linkStyles from '@xo-union/tk-component-footer-nav/lib/css/index.css'
import LazyLoad from 'react-lazyload'
import { FC, PropsWithChildren } from 'react'
import { FEATURES } from '@/modules/shared/Feature'

// Just for better TS support
declare global {
  interface Window {
    UnionConsentManagement: { togglePreferencesModal: () => void }
  }
}

export const privacyChoicesHandler = () => {
  if (typeof window !== 'undefined') {
    window.UnionConsentManagement?.togglePreferencesModal()
  }
}

const footerLinksData: { content: string; href: string; onClick?: undefined | (() => void) }[] = [
  { content: 'Privacy Policy', href: 'https://www.theknotww.com/privacy-policy/' },
  { content: 'Terms of Use', href: 'https://www.theknotww.com/terms-of-use/' },
  { content: 'Accessibility', href: 'https://www.theknotww.com/accessibility-statement/' },
  {
    content: 'Do Not Sell My Info',
    href: 'https://theknotww.zendesk.com/hc/en-us/requests/new?ticket_form_id=360000590371',
  },
  { content: 'CA Privacy', href: 'https://www.theknotww.com/ca-collection-notice/' },
  {
    content: 'Customer Service',
    href: 'https://helpcenter.theknot.com/',
  },
  { content: 'Shipping + Order Policies', href: 'https://www.theknot.com/paper/shipping-order-policies' },
  { content: 'Offer Terms', href: 'https://www.theknot.com/paper/offers' },
]

// Conditionally adding CMP link to footer data links
if (FEATURES.cmp) {
  footerLinksData.push({ content: 'Your Privacy Choices', onClick: privacyChoicesHandler, href: '#' })
}

const CopyrightItem = (
  <div className={linkStyles.copyrightItem} style={{ color: '#fff' }}>
    {' '}
    {`© 2019-${new Date().getFullYear()} Save Our Date, LLC`}{' '}
  </div>
)

export const customFooterLinks = () => (
  <LazyLoad once>
    <nav className={linkStyles.businessLinksContainer}>
      {footerLinksData.map((link, index) => (
        <FooterLink className={linkStyles.businessItemBase} onClick={link.onClick} key={index} href={link.href}>
          {link.content}
        </FooterLink>
      ))}
      {CopyrightItem}
    </nav>
  </LazyLoad>
)

const Footer: FC<PropsWithChildren<unknown>> = () => (
  <FooterNav analyticsProps={{ product: 'paper' }} renderFooterLinks={customFooterLinks} ssi={true} />
)

export default Footer
