import { categoriesHrefToCategoryMap as categoriesHrefToCategoryMapTK } from '@/data/updatedCategoryHierarchyData'
import { categoriesHrefToCategoryMap as categoriesHrefToCategoryMapWW } from '@/data/updatedCategoryHierarchyData-ww'
import { isTenant } from '@/utils/config'

import { Category, HrefToCategoryMap } from '@/data/types'

export const getValidCategoryUrl = (path: string) => {
  let result: string
  const isSaveTheDate = /(?!\/products).*\?.*save-the-dates$/.test(path)
  result = path.split('?')[0]

  switch (true) {
    case /(?!\/products).*\?.*format=standard$/.test(path) && isSaveTheDate:
      result += '?format=standard'
      break
    case /(?!\/products).*\?.*format=postcard$/.test(path) && isSaveTheDate:
      result += '?format=postcard'
      break
    case /(?!\/products).*\?.*format=all-in-one$/.test(path):
      result += '?format=all-in-one'
      break
    case /(?!\/products).*\?.*format=petite$/.test(path):
      result += '?format=petite'
      break
    case /(?!\/products).*\?.*format=magnet$/.test(path):
      result += '?format=magnet'
      break
  }

  return result
}

const createDomainCategoryObject = async (urlPath: string) => {
  const url = getValidCategoryUrl(urlPath)
  const category = await findCategoryObject(url)
  if (!category) return undefined

  return { category: category, seoTags: category.seoTags }
}

const findCategoryObject = async (path: string): Promise<Category> => {
  const categories = getEnabledHrefCategories()

  return categories[path] ?? undefined
}

function getEnabledHrefCategories(): HrefToCategoryMap {
  return isTenant('ww') ? categoriesHrefToCategoryMapWW : categoriesHrefToCategoryMapTK
}

export { createDomainCategoryObject }
