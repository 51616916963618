import { H1, H2, H3, H4, Body1, Body2, Editorial, Hero } from '@xo-union/tk-ui-typography'
import { isTenant } from '@/utils/config'

import cx from 'classnames'

import styles from './typography.module.scss'

const H1WW = (props: React.ComponentProps<typeof H1>) => <H1 {...props} className={cx(styles.h1, props.className)} />
const H2WW = (props: React.ComponentProps<typeof H2>) => <H2 {...props} className={cx(styles.h2, props.className)} />
const H3WW = (props: React.ComponentProps<typeof H3>) => <H3 {...props} className={cx(styles.h3, props.className)} />
const H4WW = (props: React.ComponentProps<typeof H4>) => <H4 {...props} className={cx(styles.h3, props.className)} />

const Body1WW = (props: React.ComponentProps<typeof Body1>) => (
  <Body1 {...props} className={cx(styles.body1, props.className)} />
)
const Body2WW = (props: React.ComponentProps<typeof Body2>) => (
  <Body2 {...props} className={cx(styles.body2, props.className)} />
)

const EditorialWW = (props: React.ComponentProps<typeof Editorial>) => (
  <Editorial {...props} className={cx(styles.editorial, props.className)} />
)

const HeroSmallTK = (props: React.ComponentProps<typeof Hero>) => <Hero {...props} size="sm" />

const HeroSmallWW = (props: React.ComponentProps<typeof Hero>) => (
  <Hero {...props} className={cx(styles.heroSmall, props.className)} />
)

const HeroMediumTK = (props: React.ComponentProps<typeof Hero>) => <Hero {...props} size="md" />

const HeroMediumWW = (props: React.ComponentProps<typeof Hero>) => (
  <Hero {...props} className={cx(styles.heroMedium, props.className)} />
)

export const PaperH1 = isTenant('ww') ? H1WW : H1
export const PaperH2 = isTenant('ww') ? H2WW : H2
export const PaperH3 = isTenant('ww') ? H3WW : H3
export const PaperH4 = isTenant('ww') ? H4WW : H4
export const PaperBody1 = isTenant('ww') ? Body1WW : Body1
export const PaperBody2 = isTenant('ww') ? Body2WW : Body2
export const PaperEditorial = isTenant('ww') ? EditorialWW : Editorial
export const PaperHeroSmall = isTenant('ww') ? HeroSmallWW : HeroSmallTK
export const PaperHeroMedium = isTenant('ww') ? HeroMediumWW : HeroMediumTK
