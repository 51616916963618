import { FEATURES } from '@/modules/shared/Feature'
import { privacyChoicesHandler } from '..'

const HOST = process.env.APP_PAPER_HOST

export const corporativeLinksData: { content: string; href?: string; rel?: string; onClick?: () => void }[] = [
  { content: 'Weddings Home', href: HOST },
  { content: 'About us', href: HOST + '/corp/about-us' },
  { content: 'Editorial Team & Policies', href: HOST + '/wedding-ideas/about-us' },
  { content: 'Nondiscrimination', href: 'https://go.weddingwire.com/nondiscrimination' },
  { content: 'Careers', href: HOST + '/corp/careers' },
  { content: 'Press Center', href: HOST + '/press' },
  { content: 'Advertising', href: HOST + '/corp/advertise' },
  { content: 'Terms of Use', href: HOST + '/corp/legal/terms-of-use', rel: 'nofollow' },
  { content: 'Privacy Policy', href: HOST + '/corp/legal/privacy-policy', rel: 'nofollow' },
  { content: 'Shipping + Order Policies', href: HOST + '/wedding-paper/page/shipping-policies' },
  { content: 'Do Not Sell My Info', href: HOST + '/privacy/noSaleForm' },
  { content: 'CA Privacy', href: HOST + '/ca-collection-notice' },
  { content: 'Web Accessibility', href: HOST + '/accessibility-statement', rel: 'nofollow' },
]

if (FEATURES.cmp) {
  corporativeLinksData.push({
    content: 'Your Privacy Choices',
    href: '#',
    rel: 'nofollow',
    onClick: privacyChoicesHandler,
  })
}

export const vendorsLinksData = [
  { content: 'Directory', href: HOST + '/wedding-vendors' },
  { content: 'Reviews', href: HOST + '/vendor-reviews' },
  { content: "Couples' Choice Awards", href: HOST + '/couples-choice-awards' },
  { content: 'Gay Weddings', href: HOST + '/gay-weddings' },
]

export const toolsLinksData = [
  { content: 'Wedding Website', href: HOST + '/wedding-websites.html' },
  { content: 'Wedding Invitations', href: HOST + '/wedding-paper/wedding-invitations' },
  { content: 'Checklist', href: HOST + '/wedding-checklists.html' },
  { content: 'Guest List', href: HOST + '/wedding-planning/wedding-guests-list.html' },
  { content: 'Budget', href: HOST + '/wedding-planning/wedding-budget.html' },
  { content: 'Vendor Manager', href: HOST + '/wedding-planning/vendor-manager.html' },
  { content: 'Seating Chart', href: HOST + '/wedding-planning/wedding-seating-tables.html' },
  {
    content: 'Hotel Blocks',
    href: 'https://hotelblocks.weddingwire.com/Group-Rate/?sc=WW_Footer&utm_source=weddingwire&utm_medium=onsite&utm_campaign=footer',
  },
  { content: 'Cost Guide', href: HOST + '/cost' },
  {
    content: 'Help',
    href: 'https://wedding-wire.zendesk.com/',
    target: '_blank',
    rel: 'nofollow noopener noreferrer',
  },
]

export const utilsLinksData = [
  { content: 'Wedding App', href: HOST + '/wedding-apps' },
  { content: 'Hashtag Generator', href: HOST + '/wedding-hashtag-generator' },
  { content: 'Wedding Giveaways', href: 'https://go.weddingwire.com/giveaways' },
  { content: 'Wedding Timeline', href: HOST + '/wedding-ideas/wedding-timeline' },
  { content: 'Color Generator', href: 'https://go.weddingwire.com/color-palette-generator' },
  { content: 'Real Weddings', href: HOST + '/wedding-photos/real-weddings' },
  { content: 'Honeymoons', href: HOST + '/honeymoons' },
]

export const goToLinksData = [{ content: "Find a couple's wedding website", href: 'https://www.weddingwire.us' }]

export const areYouVendorLinksData = [
  { content: 'Learn about Weddingwire for Business', href: HOST + '/vendors/home' },
  { content: 'Visit vendor support', href: 'https://support.weddingwire.com/hc/en-us' },
]

export const downloadAppLinksData = [
  {
    href: 'https://app.appsflyer.com/id316565575?pid=WP-iOS-US&amp;c=WP-US-LANDINGS&amp;s=us',
    src: 'https://www.weddingwire.com/assets/img/footer/appstore.png',
    srcset: 'https://www.weddingwire.com/assets/img/footer/appstore@2x.png 2x',
    alt: 'Download the WeddingWire App on the App Store',
  },
  {
    href: 'https://app.appsflyer.com/com.weddingwire.user?pid=WP-Android-US&amp;c=WP-US-LANDINGS',
    src: 'https://www.weddingwire.com/assets/img/footer/googleplay.png',
    srcset: 'https://www.weddingwire.com/assets/img/footer/googleplay@2x.png 2x',
    alt: 'Get the WeddingWire App on Google Play',
  },
]

export const countryEuropeLinksData = [
  {
    href: 'https://www.bodas.net',
    text: 'Spain',
    img: 'es.png',
  },
  {
    href: 'https://www.mariages.net',
    text: 'France',
    img: 'fr.png',
  },
  {
    href: 'https://www.matrimonio.com',
    text: 'Italy',
    img: 'it.png',
  },
  {
    href: 'https://www.hitched.co.uk',
    text: 'United Kingdom',
    img: 'uk.png',
  },
  {
    href: 'https://www.hitched.ie',
    text: 'Ireland',
    img: 'ie.png',
  },
  {
    href: 'https://www.casamentos.pt',
    text: 'Portugal',
    img: 'pt.png',
  },
]

export const countryAsiaLinksData = [
  {
    href: 'https://www.weddingwire.in',
    text: 'India',
    img: 'in.png',
  },
]

export const countryAmericaLinksData = [
  {
    href: 'https://www.bodas.com.mx',
    text: 'Mexico',
    img: 'mx.png',
  },
  {
    href: 'https://www.matrimonios.cl',
    text: 'Chile',
    img: 'cl.png',
  },
  {
    href: 'https://www.casamientos.com.ar/',
    text: 'Argentina',
    img: 'ar.png',
  },
  {
    href: 'https://www.casamentos.com.br',
    text: 'Brazil',
    img: 'br.png',
  },
  {
    href: 'https://www.matrimonio.com.co',
    text: 'Colombia',
    img: 'co.png',
  },
  {
    href: 'https://www.matrimonio.com.pe',
    text: 'Peru',
    img: 'pe.png',
  },
  {
    href: 'https://www.casamiento.com.uy',
    text: 'Uruguay',
    img: 'uy.png',
  },
  {
    href: 'https://www.weddingwire.ca',
    text: 'Canada',
    img: 'ca.png',
  },
]

export const Facebook = () => (
  <svg viewBox="0 0 1792 1792">
    <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"></path>
  </svg>
)

export const Twitter = () => (
  <svg viewBox="0 0 502 408">
    <path d="M501.625 48.375c-18.477 8.203-38.281 13.71-59.102 16.21 21.25-12.733 37.579-32.89 45.235-56.874a206.477 206.477 0 01-65.313 24.922c-18.75-20-45.468-32.461-75.039-32.461-56.797 0-102.851 46.016-102.851 102.812 0 8.047.937 15.899 2.695 23.438C161.781 122.125 86.04 81.188 35.297 18.96 26.43 34.195 21.39 51.85 21.39 70.68c0 35.664 18.125 67.148 45.742 85.625-16.875-.547-32.735-5.196-46.602-12.89v1.288c0 49.844 35.469 91.367 82.461 100.86-8.594 2.343-17.695 3.593-27.07 3.593-6.64 0-13.086-.625-19.375-1.875 13.125 40.82 51.094 70.625 96.055 71.446-35.157 27.578-79.493 43.984-127.696 43.984-8.32 0-16.484-.469-24.492-1.445 45.469 29.218 99.531 46.21 157.617 46.21 189.14 0 292.578-156.68 292.578-292.538 0-4.454-.117-8.946-.273-13.32 20.078-14.493 37.5-32.618 51.289-53.243z"></path>
  </svg>
)

export const Pinterest = () => (
  <svg viewBox="0 0 1792 1792">
    <path d="M1664 896q0 209-103 385.5t-279.5 279.5-385.5 103q-111 0-218-32 59-93 78-164 9-34 54-211 20 39 73 67.5t114 28.5q121 0 216-68.5t147-188.5 52-270q0-114-59.5-214t-172.5-163-255-63q-105 0-196 29t-154.5 77-109 110.5-67 129.5-21.5 134q0 104 40 183t117 111q30 12 38-20 2-7 8-31t8-30q6-23-11-43-51-61-51-151 0-151 104.5-259.5t273.5-108.5q151 0 235.5 82t84.5 213q0 170-68.5 289t-175.5 119q-61 0-98-43.5t-23-104.5q8-35 26.5-93.5t30-103 11.5-75.5q0-50-27-83t-77-33q-62 0-105 57t-43 142q0 73 25 122l-99 418q-17 70-13 177-206-91-333-281t-127-423q0-209 103-385.5t279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
  </svg>
)

export const Instagram = () => (
  <svg viewBox="0 0 1792 1792">
    <path d="M1152 896q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm138 0q0 164-115 279t-279 115-279-115-115-279 115-279 279-115 279 115 115 279zm108-410q0 38-27 65t-65 27-65-27-27-65 27-65 65-27 65 27 27 65zm-502-220q-7 0-76.5-.5t-105.5 0-96.5 3-103 10-71.5 18.5q-50 20-88 58t-58 88q-11 29-18.5 71.5t-10 103-3 96.5 0 105.5.5 76.5-.5 76.5 0 105.5 3 96.5 10 103 18.5 71.5q20 50 58 88t88 58q29 11 71.5 18.5t103 10 96.5 3 105.5 0 76.5-.5 76.5.5 105.5 0 96.5-3 103-10 71.5-18.5q50-20 88-58t58-88q11-29 18.5-71.5t10-103 3-96.5 0-105.5-.5-76.5.5-76.5 0-105.5-3-96.5-10-103-18.5-71.5q-20-50-58-88t-88-58q-29-11-71.5-18.5t-103-10-96.5-3-105.5 0-76.5.5zm768 630q0 229-5 317-10 208-124 322t-322 124q-88 5-317 5t-317-5q-208-10-322-124t-124-322q-5-88-5-317t5-317q10-208 124-322t322-124q88-5 317-5t317 5q208 10 322 124t124 322q5 88 5 317z"></path>
  </svg>
)

export interface SocialLinksProps {
  href: string
  title: string
  svg: any
}

export const socialLinksData = [
  {
    href: 'https://www.facebook.com/weddingwire',
    title: 'Facebook',
    svg: <Facebook />,
  },
  {
    href: 'https://twitter.com/weddingwire',
    title: 'Twitter',
    svg: <Twitter />,
  },
  {
    href: 'https://www.pinterest.com/weddingwire',
    title: 'Pinterest',
    svg: <Pinterest />,
  },
  {
    href: 'https://www.instagram.com/weddingwire',
    title: 'Instagram',
    svg: <Instagram />,
  },
]
