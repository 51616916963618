// WW
import { HrefToCategoryMap } from './types'
import { SkuToCategoryMap } from './types'
import { Category } from './types'

const saveTheDatesLink = '/save-the-dates'
const changeTheDatesLink = '/change-the-dates'
const invitationsLink = '/invitations'
const ceremonyReceptionLink = '/ceremony-reception'
const thankYousLink = '/thank-yous'
const envelopesLink = '/envelopes'

const saveTheDateCards: Category = {
  sku: 'SDC',
  name: 'Save the Date Cards',
  seoTags: {
    title: 'Save the Date Cards | Free Samples | WeddingWire',
    description:
      'Save-the-date cards that are easy to customize and make it easy to stick to your budget. Shop your favorite designs and get free save-the-date samples.',
    abstract:
      'Save-the-date cards that are easy to customize and make it easy to stick to your budget. Shop your favorite designs and get free save-the-date samples.',
    robots: 'index, follow',
  },
  href: `/save-the-date-cards`,
  parentSku: 'STDA',
}
const saveTheDatePostCards: Category = {
  sku: 'SDP',
  name: 'Save the Date Postcards',
  seoTags: {
    title: 'Save The Date Postcards | Easily Customizable Designs | WeddingWire',
    description:
      'Make your wedding something to remember with custom save-the-date postcards. Shop your favorite save-the-date postcard designs and easily stick to your budget.',
    abstract:
      'Make your wedding something to remember with custom save-the-date postcards. Shop your favorite save-the-date postcard designs and easily stick to your budget.',
    robots: 'index, follow',
  },
  href: `/save-the-date-postcards`,
  parentSku: 'STDA',
}

export const saveTheDates: Category = {
  sku: 'STDA',
  name: 'Save the Dates',
  seoTags: {
    title: 'Wedding Save The Dates | Elegant to Rustic Designs | WeddingWire',
    description:
      'Announce your wedding with customizable save-the-date cards. With hundreds of styles, from elegant to rustic, you can find one that matches your wedding vision.',
    abstract:
      'Announce your wedding with customizable save-the-date cards. With hundreds of styles, from elegant to rustic, you can find one that matches your wedding vision.',
    robots: 'noindex, nofollow',
  },
  href: saveTheDatesLink,
  subCategories: [saveTheDateCards, saveTheDatePostCards],
}

const changeTheDateCards: Category = {
  sku: 'CDC',
  name: 'Change the Date Cards',
  seoTags: {
    title: 'Change The Date Wedding Cards | Easily Customizable Designs |  WeddingWire',
    description:
      'Need to change the date of your wedding? Shop custom change the date cards today. Find your favorite design and easily customize.',
    abstract:
      'Need to change the date of your wedding? Shop custom change the date cards today. Find your favorite design and easily customize.',
    robots: 'index, follow',
  },
  href: `/change-the-date-cards`,
  parentSku: 'CTDA',
}
const changeTheDatePostCards: Category = {
  sku: 'CDP',
  name: 'Change the Date Postcards',
  seoTags: {
    title: 'Change The Date Wedding Postcards | Easily Customizable Designs | WeddingWire',
    description:
      'Need to change the date of your wedding? Shop custom change the date postcards today. Find your favorite design and easily customize.',
    abstract:
      'Need to change the date of your wedding? Shop custom change the date postcards today. Find your favorite design and easily customize.',
    robots: 'index, follow',
  },
  href: `/change-the-date-postcards`,
  parentSku: 'CTDA',
}

export const changeTheDates: Category = {
  sku: 'CTDA',
  name: 'Change the Dates',
  seoTags: {
    title: 'Change The Dates | Wedding Postponement Stationery | WeddingWire',
    description:
      'Change of plans? Update your guests about your wedding postponement in style with affordable, customizable wedding change-the-date cards.',
    abstract:
      'Change of plans? Update your guests about your wedding postponement in style with affordable, customizable wedding change-the-date cards.',
    robots: 'noindex, nofollow',
  },
  href: changeTheDatesLink,
  subCategories: [changeTheDateCards, changeTheDatePostCards],
}

const weddingInvitations: Category = {
  sku: 'WIN',
  name: 'Wedding Invitations',
  seoTags: {
    title: 'Wedding Invitations | Free Samples | WeddingWire',
    description:
      'Wedding invitations that are easy to customize and make it easy to stick to your budget. Shop your favorite designs and get free invitation samples.',
    abstract:
      'Wedding invitations that are easy to customize and make it easy to stick to your budget. Shop your favorite designs and get free invitation samples.',
    robots: 'index, follow',
  },
  href: `/wedding-invitations`,
  parentSku: 'INVI',
}
const rsvpCards: Category = {
  sku: 'RSC',
  name: 'RSVP Cards',
  seoTags: {
    title: 'Wedding RSVP Cards | Easily Customizable Designs | WeddingWire',
    description:
      'Wedding RSVP cards that are easy to customize and make it easy to stick to your budget. Shop your favorite response card designs with matching invitations.',
    abstract:
      'Wedding RSVP cards that are easy to customize and make it easy to stick to your budget. Shop your favorite response card designs with matching invitations.',
    robots: 'index, follow',
  },
  href: `/wedding-rsvp-cards`,
  parentSku: 'INVI',
}
const enclosureCards: Category = {
  sku: 'ENC',
  name: 'Enclosure Cards',
  seoTags: {
    title: 'Enclosure Cards | Easily Customizable Designs | WeddingWire',
    description:
      'Enclosure cards that are easy to customize and make it easy to stick to your budget. Shop your favorite enclosure card designs with matching invitations.',
    abstract:
      'Enclosure cards that are easy to customize and make it easy to stick to your budget. Shop your favorite enclosure card designs with matching invitations.',
    robots: 'index, follow',
  },
  href: `/wedding-enclosure-cards`,
  parentSku: 'INVI',
}
const envelopeLiners: Category = {
  sku: 'EVL',
  name: 'Envelope Liners',
  seoTags: {
    title: 'Envelope Liners | Easily Customizable Designs | WeddingWire',
    description:
      'Shop envelope liners for your wedding invitations that showcase your style and fit your budget. Customizing your envelope liners is easy and free.',
    abstract:
      'Shop envelope liners for your wedding invitations that showcase your style and fit your budget. Customizing your envelope liners is easy and free.',
    robots: 'index, follow',
  },
  href: `/wedding-envelope-liners`,
  parentSku: 'INVI',
}
const bridalShowerInvitations: Category = {
  sku: 'BRI',
  name: 'Bridal Shower Invitations',
  seoTags: {
    title: 'Bridal Shower Invitations & Wedding Shower Invites | WeddingWire',
    description:
      'Bridal shower invitations that are easy to customize and make it easy to stick to your budget. Shop your favorite bridal and wedding shower invitation designs for your wedding.',
    abstract:
      'Bridal shower invitations that are easy to customize and make it easy to stick to your budget. Shop your favorite bridal and wedding shower invitation designs for your wedding.',
    robots: 'index, follow',
  },
  href: `/bridal-shower-invitations`,
  parentSku: 'INVI',
}
const rehearsalDinnerInvitatons: Category = {
  sku: 'RDI',
  name: 'Rehearsal Dinner Invitations',
  seoTags: {
    title: 'Rehersal Dinner Invitations | Easily Customizable Designs | WeddingWire',
    description:
      'Rehearsal dinner invitations that are easy to customize and make it easy to stick to your budget. Shop your favorite rehearsal dinner invite designs for your wedding.',
    abstract:
      'Rehearsal dinner invitations that are easy to customize and make it easy to stick to your budget. Shop your favorite rehearsal dinner invite designs for your wedding.',
    robots: 'index, follow',
  },
  href: `/rehearsal-dinner-invitations`,
  parentSku: 'INVI',
}

export const invitations: Category = {
  sku: 'INVI',
  name: 'Invitations',
  seoTags: {
    title: 'Wedding Invitations | Elegant to Rustic Designs | WeddingWire',
    description:
      'Explore hundreds of affordable wedding invitations that are just your style. From elegant to rustic, customize an invitation designed for your love story.',
    abstract:
      'Explore hundreds of affordable wedding invitations that are just your style. From elegant to rustic, customize an invitation designed for your love story.',
    robots: 'noindex, nofollow',
  },
  href: invitationsLink,
  subCategories: [
    weddingInvitations,
    enclosureCards,
    rsvpCards,
    envelopeLiners,
    bridalShowerInvitations,
    rehearsalDinnerInvitatons,
  ],
}

const programs: Category = {
  sku: 'PRG',
  name: 'Programs',
  seoTags: {
    title: 'Wedding Programs | Easily Customizable Designs | WeddingWire',
    description:
      'Wedding programs that are easy to customize and make it easy to stick to your budget. Shop your favorite wedding program designs for your wedding.',
    abstract:
      'Wedding programs that are easy to customize and make it easy to stick to your budget. Shop your favorite wedding program designs for your wedding.',
    robots: 'index, follow',
  },
  href: `/wedding-programs`,
  parentSku: 'CERE',
}
const placeCards: Category = {
  sku: 'PLC',
  name: 'Place Cards',
  seoTags: {
    title: 'Wedding Place Cards | Easily Customizable Designs | WeddingWire',
    description:
      'Wedding place cards that are easy to customize and make it easy to stick to your budget. Shop your favorite wedding place cards designs for your wedding reception.',
    abstract:
      'Wedding place cards that are easy to customize and make it easy to stick to your budget. Shop your favorite wedding place cards designs for your wedding reception.',
    robots: 'index, follow',
  },
  href: `/wedding-place-cards`,
  parentSku: 'CERE',
}
const menus: Category = {
  sku: 'MEN',
  name: 'Menus',
  seoTags: {
    title: 'Wedding Menus for Guests | Easily Customizable Designs | WeddingWire',
    description:
      'Wedding menus that are easy to customize and make it easy to stick to your budget. Shop your favorite wedding menu designs for your wedding reception.',
    abstract:
      'Wedding menus that are easy to customize and make it easy to stick to your budget. Shop your favorite wedding menu designs for your wedding reception.',
    robots: 'index, follow',
  },
  href: `/wedding-menus`,
  parentSku: 'CERE',
}
const tableNumbers: Category = {
  sku: 'TNB',
  name: 'Table Numbers',
  seoTags: {
    title: 'Wedding Table Numbers | Easily Customizable Designs | WeddingWire',
    description:
      'Wedding table numbers that are easy to customize and make it easy to stick to your budget. Shop your favorite table number designs for your wedding reception.',
    abstract:
      'Wedding table numbers that are easy to customize and make it easy to stick to your budget. Shop your favorite table number designs for your wedding reception.',
    robots: 'index, follow',
  },
  href: `/wedding-table-numbers`,
  parentSku: 'CERE',
}
const stickers: Category = {
  sku: 'STK',
  name: 'Stickers',
  seoTags: {
    title: 'Wedding Stickers | Easily Customizable Designs | WeddingWire',
    description:
      'Wedding stickers that are easy to customize and make it easy to stick to your budget. Shop your favorite wedding sticker designs for your wedding.',
    abstract:
      'Wedding stickers that are easy to customize and make it easy to stick to your budget. Shop your favorite wedding sticker designs for your wedding.',
    robots: 'index, follow',
  },
  href: `/wedding-stickers`,
  parentSku: 'CERE',
}
const giftTags: Category = {
  sku: 'GFT',
  name: 'Gift Tags',
  seoTags: {
    title: 'Wedding Gift Tags | Easily Customizable Designs | WeddingWire',
    description:
      'Wedding gift tags that are easy to customize and make it easy to stick to your budget. Shop your favorite wedding gift tag designs for your wedding.',
    abstract:
      'Wedding gift tags that are easy to customize and make it easy to stick to your budget. Shop your favorite wedding gift tag designs for your wedding.',
    robots: 'index, follow',
  },
  href: `/wedding-gift-tags`,
  parentSku: 'CERE',
}
const guestBooks: Category = {
  sku: 'GBK',
  name: 'Guest Books',
  seoTags: {
    title: 'Wedding Guest Books | Easily Customizable Designs | WeddingWire',
    description:
      'Wedding guest books that are easy to customize and make it easy to stick to your budget. Shop your favorite guest book designs for your wedding.',
    abstract:
      'Wedding guest books that are easy to customize and make it easy to stick to your budget. Shop your favorite guest book designs for your wedding.',
    robots: 'index, follow',
  },
  href: `/wedding-guest-books`,
  parentSku: 'CERE',
}

export const ceremonyAndReception: Category = {
  sku: 'CERE',
  name: 'Ceremony & Reception',
  seoTags: {
    title: 'Ceremony, Reception and Wedding Day Stationery | WeddingWire',
    description:
      'Wow guests with customizable ceremony and reception stationery. Find memorable touches for your wedding day including programs, menus, guest books and more.',
    abstract:
      'Wow guests with customizable ceremony and reception stationery. Find memorable touches for your wedding day including programs, menus, guest books and more.',
    robots: 'noindex, nofollow',
  },
  href: ceremonyReceptionLink,
  subCategories: [programs, placeCards, guestBooks, menus, stickers, tableNumbers, giftTags],
}

const thankyouCards: Category = {
  sku: 'TYC',
  name: 'Thank You Cards',
  seoTags: {
    title: 'Thank You Cards | Easily Customizable Designs | WeddingWire',
    description:
      'Thank you cards that are easy to customize and make it easy to stick to your budget. Shop your favorite thank you card designs for your wedding.',
    abstract:
      'Thank you cards that are easy to customize and make it easy to stick to your budget. Shop your favorite thank you card designs for your wedding.',
    robots: 'index, follow',
  },
  href: `/thank-you-cards`,
  parentSku: 'TKYS',
}
const thankyouPostCards: Category = {
  sku: 'TYP',
  name: 'Thank You Postcards',
  seoTags: {
    title: 'Thank You Postcards | Easily Customizable Designs | WeddingWire',
    description:
      'Thank you postcards that are easy to customize and make it easy to stick to your budget. Shop your favorite thank you postcard designs for your wedding.',
    abstract:
      'Thank you postcards that are easy to customize and make it easy to stick to your budget. Shop your favorite thank you postcard designs for your wedding.',
    robots: 'index, follow',
  },
  href: `/thank-you-postcards`,
  parentSku: 'TKYS',
}

export const thankyou: Category = {
  sku: 'TKYS',
  name: 'Thank Yous',
  seoTags: {
    title: 'Wedding Thank You Cards | Elegant to Rustic Designs | WeddingWire',
    description:
      'Show guests your love and appreciation with customizable wedding thank-you cards. Available in a variety of designs, from elegant to rustic.',
    abstract:
      'Show guests your love and appreciation with customizable wedding thank-you cards. Available in a variety of designs, from elegant to rustic.',
    robots: 'noindex, nofollow',
  },
  href: thankYousLink,
  subCategories: [thankyouCards, thankyouPostCards],
}

const responseCardEnvelopes: Category = {
  sku: 'RSCENV',
  name: 'Response Card Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const weddingInvitationEnvelopes: Category = {
  sku: 'WINENV',
  name: 'Wedding Invitation Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const saveTheDateCardEnvelopes: Category = {
  sku: 'SDCENV',
  name: 'Save the Date Card Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const bridalShowerInvitationEnvelopes: Category = {
  sku: 'BRIENV',
  name: 'Bridal Shower Invitation Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const rehearsalDinnerInvitationEnvelopes: Category = {
  sku: 'RDIENV',
  name: 'Rehearsal Dinner Invitation Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const thankYouCardEnvelopes: Category = {
  sku: 'TYCENV',
  name: 'Thank You Card Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const changeTheDateCardEnvelopes: Category = {
  sku: 'CDCENV',
  name: 'Change The Date Card Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const envelopes: Category = {
  sku: 'ENVE',
  name: 'Envelopes',
  seoTags: {
    title: 'Wedding Invitation and RSVP Envelopes | WeddingWire',
    description:
      'Browse our collection of envelopes to put the perfect finishing touch on your wedding invitations and stationery.',
    abstract:
      'Browse our collection of envelopes to put the perfect finishing touch on your wedding invitations and stationery.',
    robots: null,
  },
  href: envelopesLink,
  subCategories: [
    responseCardEnvelopes,
    weddingInvitationEnvelopes,
    saveTheDateCardEnvelopes,
    bridalShowerInvitationEnvelopes,
    rehearsalDinnerInvitationEnvelopes,
    thankYouCardEnvelopes,
    changeTheDateCardEnvelopes,
  ],
}

export const categoriesSkuToCategoryMap: SkuToCategoryMap = {
  [thankyou.sku]: thankyou,
  [thankyouPostCards.sku]: thankyouPostCards,
  [thankyouCards.sku]: thankyouCards,
  [ceremonyAndReception.sku]: ceremonyAndReception,
  [guestBooks.sku]: guestBooks,
  [giftTags.sku]: giftTags,
  [stickers.sku]: stickers,
  [tableNumbers.sku]: tableNumbers,
  [menus.sku]: menus,
  [placeCards.sku]: placeCards,
  [programs.sku]: programs,
  [invitations.sku]: invitations,
  [weddingInvitations.sku]: weddingInvitations,
  [rsvpCards.sku]: rsvpCards,
  [enclosureCards.sku]: enclosureCards,
  [envelopeLiners.sku]: envelopeLiners,
  [bridalShowerInvitations.sku]: bridalShowerInvitations,
  [rehearsalDinnerInvitatons.sku]: rehearsalDinnerInvitatons,
  [changeTheDates.sku]: changeTheDates,
  [changeTheDateCards.sku]: changeTheDateCards,
  [changeTheDatePostCards.sku]: changeTheDatePostCards,
  [saveTheDates.sku]: saveTheDates,
  [saveTheDateCards.sku]: saveTheDateCards,
  [saveTheDatePostCards.sku]: saveTheDatePostCards,
  [envelopes.sku]: envelopes,
  [responseCardEnvelopes.sku]: responseCardEnvelopes,
  [weddingInvitationEnvelopes.sku]: weddingInvitationEnvelopes,
  [saveTheDateCardEnvelopes.sku]: saveTheDateCardEnvelopes,
  [bridalShowerInvitationEnvelopes.sku]: bridalShowerInvitationEnvelopes,
  [rehearsalDinnerInvitationEnvelopes.sku]: rehearsalDinnerInvitationEnvelopes,
  [thankYouCardEnvelopes.sku]: thankYouCardEnvelopes,
  [changeTheDateCardEnvelopes.sku]: changeTheDateCardEnvelopes,
}

export const categoriesHrefToCategoryMap: HrefToCategoryMap = {
  [thankyou.href]: thankyou,
  [thankyouPostCards.href]: thankyouPostCards,
  [thankyouCards.href]: thankyouCards,
  [ceremonyAndReception.href]: ceremonyAndReception,
  [guestBooks.href]: guestBooks,
  [giftTags.href]: giftTags,
  [stickers.href]: stickers,
  [tableNumbers.href]: tableNumbers,
  [menus.href]: menus,
  [placeCards.href]: placeCards,
  [programs.href]: programs,
  [invitations.href]: invitations,
  [weddingInvitations.href]: weddingInvitations,
  [rsvpCards.href]: rsvpCards,
  [enclosureCards.href]: enclosureCards,
  [envelopeLiners.href]: envelopeLiners,
  [bridalShowerInvitations.href]: bridalShowerInvitations,
  [rehearsalDinnerInvitatons.href]: rehearsalDinnerInvitatons,
  [changeTheDates.href]: changeTheDates,
  [changeTheDateCards.href]: changeTheDateCards,
  [changeTheDatePostCards.href]: changeTheDatePostCards,
  [saveTheDates.href]: saveTheDates,
  [saveTheDateCards.href]: saveTheDateCards,
  [saveTheDatePostCards.href]: saveTheDatePostCards,
  [envelopes.href]: envelopes,
  [responseCardEnvelopes.href]: responseCardEnvelopes,
  [weddingInvitationEnvelopes.href]: weddingInvitationEnvelopes,
  [saveTheDateCardEnvelopes.href]: saveTheDateCardEnvelopes,
  [bridalShowerInvitationEnvelopes.href]: bridalShowerInvitationEnvelopes,
  [rehearsalDinnerInvitationEnvelopes.href]: rehearsalDinnerInvitationEnvelopes,
  [thankYouCardEnvelopes.href]: thankYouCardEnvelopes,
  [changeTheDateCardEnvelopes.href]: changeTheDateCardEnvelopes,
}
