import { ComponentType, useState, useEffect, useRef, PropsWithChildren, SyntheticEvent } from 'react'
import styles from './styles.scss'
import CategoryLink, { SubnavLinkData } from '@/modules/core/components/CategoryLink'
import { trackInteraction } from '@/modules/core/services/analytics'
import { Category } from '@/data/types'

export interface BadgesNavProps {
  loggedIn: boolean
  categories: SubnavLinkData[]
  selectedCategory?: SubnavLinkData
}

const runAnalyticsEvent = (loggedIn: boolean, destinationCategory: string, currentCategory: string | undefined) => {
  const event = loggedIn ? 'NavigationAuthed' : 'Navigation'
  const params = {
    label: `d-mobile_app+s-paper+o-{${currentCategory?.replace('/', '')}}+dt-{${destinationCategory.replace('/', '')}}`,
  }
  trackInteraction(event, params)
}

const BadgesNav: ComponentType<PropsWithChildren<BadgesNavProps>> = ({ loggedIn, categories, selectedCategory }) => {
  const isBrowser = () => typeof window !== 'undefined'
  const refToElement = useRef<HTMLUListElement>(null)
  const [currentCategories, setCurrentCategories] = useState<SubnavLinkData[]>(categories)

  useEffect(() => {
    if (isBrowser()) {
      if (typeof localStorage !== undefined && localStorage.getItem) {
        setCurrentCategories(JSON.parse(localStorage.getItem('currentCategories')!))
      }
    }
  }, [])

  useEffect(() => {
    if (typeof localStorage !== undefined && localStorage.setItem) {
      localStorage.setItem('currentCategories', JSON.stringify(currentCategories))
    }
  }, [currentCategories])
  const handleOnClick = (_event: SyntheticEvent, category: Category) => {
    runAnalyticsEvent(loggedIn, category.href, selectedCategory?.href)
    refToElement.current?.scrollTo({ left: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    setCurrentCategories((current) => (!selectedCategory ? categories : selectedCategory.subCategories || current))
  }, [selectedCategory, categories])

  return (
    <div className={styles.scrollWrapper}>
      <ul className={styles.badgeList} ref={refToElement}>
        {!selectedCategory?.subCategories && (
          <span className={selectedCategory ? styles.selectedCategory : styles.hideEmptyItem}>
            {selectedCategory?.name}
          </span>
        )}
        {currentCategories.map((category) => (
          <li key={category.id} className={selectedCategory?.href === category.href ? styles.hideSelectedCategory : ''}>
            <CategoryLink onClick={(e) => handleOnClick(e, category as Category)} subnavLinkData={category} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BadgesNav
