import { useEffect, useState } from 'react'

export const isWindowDefined = typeof window !== 'undefined'

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState<number | undefined>()
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return !!width && width <= 768
}

export default useCheckMobileScreen
