import { ComponentType, PropsWithChildren } from 'react'
import { ImageTKWW } from '@/ui/ImageWrapper/ImageTKWW'
import styles from './styles.scss'

interface CountrySelectorProps {
  title: string
  data: any
}

const CountrySelectorContent: ComponentType<PropsWithChildren<CountrySelectorProps>> = ({ title, data }) => {
  return (
    <>
      <div className={styles.countrySelectorModal__content}>
        <h4 className={styles.countrySelectorModal__subtitle}>{title}</h4>
        <ul className={styles.countrySelectorModalList}>
          {data.map((link: any, index: number) => (
            <li key={index}>
              <a className={styles.countrySelectorModalList__item} rel="nofollow" href={link.href} title={link.text}>
                <ImageTKWW
                  src={'https://www.weddingwire.com/assets/img/paper/flags/' + link.img + ''}
                  className={styles.countrySelectorModalList__flag}
                  width={18}
                  height={12}
                  alt={link.text}
                />
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default CountrySelectorContent
