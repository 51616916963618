import { ComponentType, PropsWithChildren } from 'react'
import Link from 'next/link'
import styles from './styles.scss'
import { SubnavLinkData } from '../../CategoryLink'
import { Category } from '@/data/types'

export interface SubNavAppProps {
  selectedCategory?: SubnavLinkData
  categories: Category[]
}

export function getCategory(categories: Category[], selectedCategory?: Category): string | null {
  if (selectedCategory?.subCategories) {
    return selectedCategory.name
  }

  const categoryName = categories
    .filter((category) => selectedCategory && category?.subCategories?.includes(selectedCategory))
    .map((category) => category.name)
  return categoryName.length > 0 ? categoryName[0] : null
}

const SubNavApp: ComponentType<PropsWithChildren<SubNavAppProps>> = ({ selectedCategory, categories }) => {
  const category = getCategory(categories, selectedCategory as Category)

  if (!category) {
    return null
  }

  return (
    <div className={styles.pageSubtitleFreeSampleContainer}>
      <div className={styles.categoryHeader}>
        <span>{category}</span>
      </div>
      <div>
        <Link href={`/page/free-wedding-invitation-samples`}>
          <a className={styles.freeSampleContainer}> Free Sample</a>
        </Link>
      </div>
    </div>
  )
}

export default SubNavApp
