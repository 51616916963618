import { handleZendeskOnError } from '@/utils/helpers'
import { updateSettingZendeskClassic } from '@tkww/paper-zendesk'
import { MouseEvent } from 'react'
import styles from './styles.scss'

export const onNavigationMenuHandle = () => {
  const dropdown = document.querySelector('.app-header-menu')
  const navOpenClass = styles['layoutHeader__navOpen']
  const isNavOpen = dropdown?.classList.contains(navOpenClass)

  updateSettingZendeskClassic('updateSettings', { zIndex: isNavOpen ? 9999 : 5 }, (error) =>
    handleZendeskOnError(error, 'header-ww')
  )
  dropdown?.classList.toggle(navOpenClass)
}

export const onSubmenuHandle = (e: MouseEvent<HTMLLIElement>) => {
  const self = e.currentTarget

  const itemElements = {
    closeSection: self.querySelector('.app-header-menu-item-closeSection'),
    dropdown: self.querySelector('.app-header-menu-itemDropdown'),
  }
  itemElements.dropdown?.classList.toggle(styles['layoutNavMenuTab--open'])

  itemElements.closeSection?.addEventListener('click', () => {
    self.classList.remove(styles['layoutNavMenuTab--open'])
  })
}
