import { isTenant } from '@/utils/config'
import cx from 'classnames'
import { AnchorHTMLAttributes, ComponentType, PropsWithChildren, useCallback, useState } from 'react'
import { LinkVariantsProps } from './Link'

import { getUrlToExternalApp } from '@/routing/externalApp'
import { basePath } from '@/utils/helpers'
import Link from 'next/link'
import stylesTK from './Link.module.scss'
import stylesWW from './ww/LinkWW.module.scss'

type DisplayLinkProps = LinkVariantsProps & PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>

const styles = isTenant('ww') ? stylesWW : stylesTK

const DisplayLink: ComponentType<DisplayLinkProps> = ({
  size = 'lg',
  color = 'primary',
  disabled,
  children,
  className,
  href,
  isExternal,
  ...props
}) => {
  const [isFetched, setIsFetched] = useState<boolean>()

  const onHoverPrefetch = useCallback(() => {
    if (!isFetched && !isExternal) {
      fetch(String(`${href?.startsWith('http') ? '' : basePath}${href}`), {
        headers: { purpose: 'prefetch' },
      }).finally(() => setIsFetched(true))
    }
  }, [href, isExternal, isFetched])

  if (!href) return <>{children}</>

  const anchorClassName = cx(styles.wrapper, styles[size], styles[color], { [styles.disabled]: disabled }, className)

  if (isExternal) {
    return (
      <a data-testid="external-link" href={getUrlToExternalApp(href)} className={anchorClassName}>
        {children}
      </a>
    )
  }

  return (
    <Link href={href}>
      <a {...props} className={anchorClassName} onMouseOver={onHoverPrefetch}>
        {children}
      </a>
    </Link>
  )
}

export default DisplayLink
