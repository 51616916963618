import { useTracking } from '@/components/Tracking/useTracking/useTracking'
import { UserProfile } from '@/components/User/UserProvider/UserProvider'
import { Category } from '@/data/types'
import {
  ceremonyAndReception,
  changeTheDates,
  invitations,
  saveTheDates,
  thankyou,
} from '@/data/updatedCategoryHierarchyData-ww'
import BadgesNavWW from '@/modules/core/components/BadgesNav/ww'
import Footer from '@/modules/core/components/Footer'
import FooterWW from '@/modules/core/components/Footer/ww'
import Header from '@/modules/core/components/Header'
import HeaderWW from '@/modules/core/components/Header/ww'
import ScrollToTop from '@/modules/core/components/ScrollToTop'
import Seo from '@/modules/core/components/Seo'
import SubNavAppWW from '@/modules/core/components/SubNavApp/ww'
import { HeaderPencilBannerProvider } from '@/modules/core/contexts/HeaderPencilBanner/HeaderPencilBanner'
import { ItemNoticeProvider, useItemNoticeContext } from '@/modules/core/contexts/ItemNotice'
import {
  MembershipDispatch,
  MembershipState,
  fetchMember,
  logOut,
  useMembership,
} from '@/modules/core/contexts/membershipAuth'
import Honeybadger from '@/modules/core/services/honeybadger.service'
import { useImpactIdentify } from '@/modules/core/services/impact'
import useCheckMobileScreen from '@/modules/helpers/mobileScreenHook'
import { OverlaySpinnerProvider as BrandedOverlaySpinnerProvider } from '@/modules/shared/BrandedOverlaySpinner/context'
import { useFeatureToggle } from '@/modules/shared/Feature/FeatureToggle/useFeatureToggle'
import { CART_ROUTE, CHECKOUT_ROUTE, CONFIRMATION_ROUTE } from '@/routing/brandRoutes'
import { routeToExternalApp } from '@/routing/externalApp'
import { trackMenuInteraction, trackProductsSearched } from '@/utils/analytics/helpers'
import { CONFIG, isTenant } from '@/utils/config'
import { handlePageLogout, handleZendeskOnError } from '@/utils/helpers'
import { holidayLink } from '@/utils/helpers/constants'
import { DefaultPageProps, ItemCounters } from '@/utils/pages'
import { HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import { PaperSubNav } from '@tkww/paper-subnav'
import { Zendesk } from '@tkww/paper-zendesk'
import create from '@xo-union/tab-accessibility'
import Layout from '@xo-union/tk-component-layout'
import { useRouter } from 'next/router'
import { ComponentType, PropsWithChildren, useEffect, useRef, useState } from 'react'
import NotificationToast from '../NotificationToast/NotificationToast'
import { createDomainCategoryObject } from './helpers'
import { useOnRouteChangeComplete } from './hooks'
import styles from './index.scss'

interface LayoutWrapperProps {
  memberData?: any
}

type LayoutComponentProps = Omit<DefaultPageProps, 'error' | 'layout' | 'cosmoboxProps'>

type WebLayoutProps = { itemCounters: ItemCounters } & LayoutComponentProps

//props wwBrand
type ExtendLayoutWrapperProps = {
  userProfile?: UserProfile | null
  isApp?: boolean
}

const weddingWireCategories: Category[] = [invitations, saveTheDates, changeTheDates, ceremonyAndReception, thankyou]

export const LayoutWrapper: ComponentType<PropsWithChildren<LayoutWrapperProps & ExtendLayoutWrapperProps>> = ({
  children,
  userProfile,
  isApp,
}) => {
  const [state, dispatch] = useMembership()
  const memberDispatch = dispatch as MembershipDispatch
  const { fetched: isAuthStatusLoaded, ...memberState } = state as MembershipState
  const { asPath, pathname } = useRouter()
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined)
  const trackingData = useTracking()
  const { isEnabled } = useFeatureToggle()
  const {
    itemNoticeState: { displayNotificationMessage, content, noticeType },
    setItemNoticeState,
    itemCountersState,
  } = useItemNoticeContext()
  const additionalLink = isEnabled('HolidayLink') && isTenant('tk') ? holidayLink : undefined

  useOnRouteChangeComplete(memberState.member)
  useImpactIdentify(memberState.member?.id, memberState.member?.email)

  useEffect(() => {
    const tabAccessibility = create()
    tabAccessibility.mount()
  }, [])

  useEffect(() => {
    !memberState.authenticated && fetchMember(memberDispatch)
  }, [memberState.authenticated, memberDispatch])

  useEffect(() => {
    createDomainCategoryObject(asPath).then((domainCategory) => {
      setSelectedCategory(domainCategory?.category)
    })
  }, [asPath])

  const handleOnItemNoticeClose = () => {
    setItemNoticeState((prev) => ({
      ...prev,
      displayNotificationMessage: false,
    }))
  }

  // Closes the item notice if router changes
  useEffect(() => {
    if (displayNotificationMessage) {
      handleOnItemNoticeClose()
    }
  }, [asPath])

  const isCartOrCheckout =
    pathname === CART_ROUTE || (pathname.includes(CHECKOUT_ROUTE) && !pathname.includes(CONFIRMATION_ROUTE))

  const onSearch = (searchTerm: string) => {
    routeToExternalApp(`/products?search=${searchTerm}`)
  }

  const isMobile = useCheckMobileScreen()

  const logoutCallbackHandler = () =>
    logOut(memberDispatch).then(() => {
      handlePageLogout()
    })

  let header, footer, badgesNav, subNavApp, loggedIn

  if (isTenant('ww')) {
    loggedIn = Boolean(userProfile)
    header = !isApp && <HeaderWW loggedIn={loggedIn} userData={userProfile} />
    footer = !isApp && <FooterWW />
    badgesNav = Boolean(isApp) && (
      <BadgesNavWW loggedIn={loggedIn} selectedCategory={selectedCategory} categories={weddingWireCategories} />
    )
    subNavApp = Boolean(isApp) && <SubNavAppWW selectedCategory={selectedCategory} categories={weddingWireCategories} />
  } else {
    loggedIn = memberState.authenticated
    header = <Header loggedIn={loggedIn} logOutCallback={logoutCallbackHandler} />
    footer = <Footer />
    badgesNav = undefined
    subNavApp = undefined
  }

  const onMenuItemInteraction = (value: string) => {
    trackMenuInteraction({
      selection: value,
      ...trackingData,
    })
  }

  const onProductsSearched = (searchTerm: string) =>
    trackProductsSearched({
      query: searchTerm,
      selection: 'paper',
      ...trackingData,
    })

  //Horrible bridgerton hack to solve the Copyright unplanned task at release date.
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(() => {
        if (typeof window === 'undefined') return

        const hasBridgerton = String(document?.getElementById('content')?.outerHTML)
          .toLowerCase()
          //submenu
          .replace('<a data-test="subnav-link" href="/paper/bridgerton">bridgerton x the knot</a>', '')
          //ff
          .replace('"bridgerton":true', '')
          .includes('bridgerton')

        const el = document.getElementById('extra-copyright')
        if (el) {
          el.innerHTML = hasBridgerton
            ? '© 2024 Netflix. All rights reserved. BRIDGERTON and Netflix marks <span style="font-family: sans-serif">™</span> Netflix. Shondaland mark <span style="font-family: sans-serif">™</span> Shondaland. Used with permission.'
            : ''
          el.style.display = hasBridgerton ? 'block' : 'none'
        }
      })

      observer.observe(ref.current, { childList: true, characterData: true, subtree: true })
      return () => observer.disconnect()
    }
  }, [])

  const searchBarPlaceholder = 'Search rustic, modern, floral...'

  return (
    <>
      <BrandedOverlaySpinnerProvider>
        <HeaderPencilBannerProvider>
          <div className={styles.layoutWrapper}>
            <Layout header={header} footer={footer}>
              <>
                {isAuthStatusLoaded && !isCartOrCheckout && (
                  <PaperSubNav
                    isUserLoggedIn={loggedIn}
                    tenant={isTenant('tk') ? 'tk' : 'ww'}
                    trackMenuInteraction={onMenuItemInteraction}
                    trackProductsSearched={onProductsSearched}
                    onSearch={onSearch}
                    cartCount={itemCountersState.cart}
                    draftCount={itemCountersState.drafts}
                    searchBarPlaceholder={searchBarPlaceholder}
                    additionalLink={additionalLink}
                  />
                )}
                {(isEnabled('ToastNotifications') || pathname.includes('your-orders/')) && (
                  <NotificationToast
                    isDisplayed={displayNotificationMessage}
                    isMobile={isMobile}
                    handleOnClose={handleOnItemNoticeClose}
                    type={noticeType}
                  >
                    {content}
                  </NotificationToast>
                )}

                <>{subNavApp}</>
                <>{badgesNav}</>
                <HoneybadgerErrorBoundary honeybadger={Honeybadger}>
                  <div ref={ref} id="content">
                    {children}
                  </div>
                </HoneybadgerErrorBoundary>
                <ScrollToTop />

                <div
                  id="extra-copyright"
                  style={{
                    backgroundColor: 'white',
                    textAlign: 'center',
                    paddingBottom: '28px',
                    display: 'none',
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                />
              </>
            </Layout>
          </div>
        </HeaderPencilBannerProvider>
      </BrandedOverlaySpinnerProvider>
    </>
  )
}

const WebLayout: ComponentType<PropsWithChildren<WebLayoutProps & ExtendLayoutWrapperProps>> = ({
  children,
  seoTags,
  userProfile,
  itemCounters,
}) => {
  const { pathname } = useRouter()
  const useSeo = pathname !== '/[...slug]'

  return (
    <>
      {useSeo && <Seo seoTags={seoTags} />}
      <Zendesk
        tenant={CONFIG.tenant.shortName}
        apiKey={String(process.env.APP_ZENDESK_API_KEY)}
        onError={(error) => handleZendeskOnError(error)}
      />
      <div>
        <ItemNoticeProvider itemCounters={itemCounters}>
          <LayoutWrapper userProfile={userProfile}>{children}</LayoutWrapper>
        </ItemNoticeProvider>
      </div>
    </>
  )
}

export const WebviewLayout: ComponentType<PropsWithChildren<WebLayoutProps & LayoutComponentProps>> = ({
  children,
  itemCounters,
}) => {
  return (
    <main>
      <ItemNoticeProvider itemCounters={itemCounters}>
        <HeaderPencilBannerProvider>
          <HoneybadgerErrorBoundary honeybadger={Honeybadger}>{children}</HoneybadgerErrorBoundary>
        </HeaderPencilBannerProvider>
      </ItemNoticeProvider>
    </main>
  )
}

export default WebLayout
