enum WWRoutes {
  Product = 'p',
  Products = 'products',
  ShippingOrderPolicies = 'shipping-policies',
}

enum TKRoutes {
  Product = 'product',
  Products = 'products',
  Canvas = 'canvas',
  Cart = 'cart',
  ReviewPage = 'order',
  ShippingOrderPolicies = 'shipping-order-policies',
}

export const getBrandRoutes = (brand = process.env.APP_BRAND || 'tk') => {
  switch (brand) {
    case 'tk':
      return TKRoutes
    case 'ww':
      return WWRoutes
    default:
      return TKRoutes
  }
}

export const BRAND_ROUTES = getBrandRoutes()

export const YOUR_DRAFTS_ROUTE = '/your-drafts'
export const CART_ROUTE = '/cart'
export const CHECKOUT_ROUTE = '/checkout'
export const CONFIRMATION_ROUTE = '/confirmation'
export const INVITATIONS_ROUTE = '/invitations'
export const PRODUCT_LIST_ROUTE = '/[...slug]'
export const REVIEW_ROUTE = '/order/order_item'

type ProductUrlArgs = {
  slug: string
  variationId?: string
  parentId?: string
  quantity?: number
}

export const buildProductUrl = ({ slug, variationId, quantity, parentId }: ProductUrlArgs) => {
  const url = `/${BRAND_ROUTES.Product}/${slug}`
  const params = new URLSearchParams()
  variationId && params.set('v', variationId)
  quantity && params.set('q', String(quantity))
  parentId && params.set('parent_item', parentId)
  return url + (params.toString() ? `?${params.toString()}` : '')
}

export const buildCanvasPath = (id: string, destination?: string) => {
  const path = `/canvas/${id}`
  return destination ? `${path}?destination=${destination}` : path
}
