import { PencilBannerData } from '@/services/contentful/plp/plp'
import { ComponentType, createContext, useContext, useState } from 'react'

type HeaderPencilBannerProps = {
  bannerData: PencilBannerData | null
  setBannerData: (data: PencilBannerData | null) => void
}

export const HeaderPencilBanner = createContext<HeaderPencilBannerProps | undefined>(undefined)

export const HeaderPencilBannerProvider: ComponentType = ({ children }) => {
  const [bannerData, setBannerData] = useState<PencilBannerData | null>(null)
  return <HeaderPencilBanner.Provider value={{ bannerData, setBannerData }}>{children}</HeaderPencilBanner.Provider>
}

export const useHeaderPencilBanner = () => {
  const context = useContext(HeaderPencilBanner)
  if (context === undefined) {
    throw new Error('useHeaderPencilBanner must be used within a HeaderPencilBannerProvider')
  }
  return context
}
