import { ComponentType, PropsWithChildren } from 'react'
import Icon from '@xo-union/tk-component-icons'
import cx from 'classnames'
import styles from './styles.module.scss'
import { useShowScrollToTopArrow } from '@/modules/helpers/scrollHook'

const ScrollToTop: ComponentType<PropsWithChildren<unknown>> = () => {
  const showArrow = useShowScrollToTopArrow()
  const onClickHandler = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  const arrowClass = showArrow ? styles.isActive : ''
  const classNames = cx(styles.scrollToTop, arrowClass)
  return (
    <button
      data-testid="scroll-to-top-button"
      className={classNames}
      onClick={onClickHandler}
      title="Scroll to Top"
      tabIndex={-1}
      aria-label="Scroll to Top"
    >
      <Icon className={styles.arrow} name="caret_up" />
    </button>
  )
}

export default ScrollToTop
