import { ComponentType, PropsWithChildren } from 'react'
import styles from './styles.scss'
import { onNavigationMenuHandle } from './headerFunctions'

interface SubmenuProps {
  title: string
  submenu: any
}

export const Submenu: ComponentType<PropsWithChildren<SubmenuProps>> = ({ title, submenu }) => {
  return (
    <section className={`${styles.layoutNavMenuTab} app-header-menu-itemDropdown`}>
      <div className={styles.layoutNavMenuTab__layout}>
        <div className={styles.layoutNavMenuTab__header}>
          <i className={styles.layoutNavMenuTab__icon}>
            <svg viewBox="0 0 20 36" width="20" height="36">
              <path d="M18.769.653a2.004 2.004 0 00-2.961 0L1.245 16.17c-.09.07-.175.147-.256.233A2.302 2.302 0 00.376 18c-.005.578.2 1.157.613 1.598.08.086.167.164.256.233l14.563 15.515a2.004 2.004 0 002.96 0c.744-.791.812-2.031.204-2.904l-.203-.25L5.449 18 18.77 3.809l.203-.25c.608-.874.54-2.113-.203-2.906z"></path>
            </svg>
          </i>
          <p className={styles.layoutNavMenuTab__title}>{title}</p>
          <i className={styles.layoutNavMenuTab__iconClose} onClick={onNavigationMenuHandle}>
            <svg viewBox="0 0 26 26" width="26" height="26">
              <path d="M12.983 10.862L23.405.439l2.122 2.122-10.423 10.422 10.423 10.422-2.122 2.122-10.422-10.423L2.561 25.527.439 23.405l10.423-10.422L.439 2.561 2.561.439l10.422 10.423z"></path>
            </svg>
          </i>
        </div>
        <div className={styles.layoutNavMenuTabSubmenu}>
          <div className={styles.layoutNavMenuTabSubmenuList}>
            <ul className={styles.layoutNavMenuTabSubmenuList__content}>
              {submenu?.map((link: any, index: number) => (
                <li
                  className={`${styles.layoutNavMenuTabSubmenuList__item} ${
                    link.colorPrimary ? styles['layoutNavMenuTabSubmenuList__item--primary'] : ''
                  }`}
                  key={index}
                >
                  <a href={link.href}>{link.content}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Submenu
