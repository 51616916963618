export const DEFAULT_SEO_META = {
  tk: {
    title: 'Wedding Invitations and Save The Dates | The Knot',
    description:
      'Browse hundreds of gorgeous, customizable wedding invitations, save-the-dates and more from our affordable wedding stationery collection.',
  },
  ww: {
    title: 'Wedding Stationery: Invitations, RSVPs & More | WeddingWire',
    description:
      'From save-the-dates, wedding invitations to thank you cards, we have your wedding stationery covered. Customization is free and so are samples. Shop today!',
  },
}
