import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const useRouterScroll = ({ left = 0, top = 0 } = {}) => {
  const router = useRouter()
  useEffect(() => {
    // This fixes an inconsistent behaviour between `<Link/>` and `next/router`
    // See https://github.com/vercel/next.js/issues/3249
    const handleRouteChangeComplete = () => {
      window.scrollTo({ top, left })
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [left, top])
}

const useShowScrollToTopArrow = () => {
  const [showScrollArrow, setShowScrollArrow] = useState<boolean>(false)

  useEffect(() => {
    const scrollListener = () => {
      setShowScrollArrow(window.scrollY > 300)
    }

    window.addEventListener('scroll', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])

  return showScrollArrow
}

export { useRouterScroll, useShowScrollToTopArrow }
