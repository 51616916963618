import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { pageView } from '@/modules/core/services/analytics'
import { useImpactIdentifyCallback } from '@/modules/core/services/impact'
import { MemberData } from '@/modules/core/contexts/membershipAuth'
import { useTracking } from '@/components/Tracking/useTracking/useTracking'

export const usePageViewRouteChangeComplete = () => {
  const trackingData = useTracking()

  // We need to avoid re-rendering, but in the meantime we can get super defensive by calculating a key
  // to be used on useCallback and avoid firing events more than once.
  const parts = String(globalThis?.window?.location?.href || '').split('?')
  const cleanUrl = parts[0]
  const searchParams = new URLSearchParams(parts[1])
  searchParams.delete('q')

  const params = Object.fromEntries(searchParams.entries())
  const key = JSON.stringify({ cleanUrl, ...params })

  useEffect(() => {
    if (!globalThis?.window) return
    pageView(trackingData)
  }, [key])
}

export const useImpactIdentifyRouteChangeComplete = (member: MemberData | null) => {
  const { events } = useRouter()
  const impactIdentifyCallback = useImpactIdentifyCallback(member)

  useEffect(() => {
    events.on('routeChangeComplete', impactIdentifyCallback)
    return () => {
      events.off('routeChangeComplete', impactIdentifyCallback)
    }
  }, [impactIdentifyCallback, events])
}

const useOnRouteChangeComplete = (member: MemberData | null) => {
  usePageViewRouteChangeComplete()
  useImpactIdentifyRouteChangeComplete(member)
}

export { useOnRouteChangeComplete }
