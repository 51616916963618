import { RefObject, useEffect, useMemo, useState } from 'react'

export default function useOnScreen(ref: RefObject<HTMLElement>, rootMargin = '0px') {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => {
    if (typeof window === 'undefined') return
    return new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), { rootMargin })
  }, [ref, rootMargin])

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (!ref.current) return
    observer?.observe(ref.current)
    return () => observer?.disconnect()
  }, [ref, observer])

  return typeof window === 'undefined' ? false : isIntersecting
}
