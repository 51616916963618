import { cloneElement, ComponentType, PropsWithChildren, useEffect, useRef } from 'react'
import styles from './index.module.scss'
import NoticeBanner from '@/modules/shared/NoticeBanner'
import { Snackbar } from '@xo-union/tk-component-alerts'
import { NotificationMessageProps } from './NotificationMessage/NotificationMessage'
import { isTenant } from '@/utils/config'

export interface NotificationToastProps {
  isDisplayed: boolean
  isMobile: boolean
  handleOnClose: () => void
  type?: 'success' | 'error' | 'warning'
}

const NotificationToast: ComponentType<PropsWithChildren<NotificationToastProps>> = ({
  children,
  isDisplayed,
  isMobile,
  handleOnClose,
  type = 'success',
}) => {
  const snackbar = useRef<any>(null)

  useEffect(() => {
    if (isDisplayed) {
      if (isMobile) {
        const notificationTimeout = 4000

        snackbar?.current?.showAndDismiss({
          data: cloneElement(children as React.ReactElement<NotificationMessageProps>, { hasSnackbarWrapper: true }),
          dismissTimeout: notificationTimeout,
        })
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }, [isDisplayed])

  const notificationToastStyleClassesArray = [styles.container]
  if (isTenant('ww')) {
    notificationToastStyleClassesArray.push(styles.container__ww)
  }
  const notificationToastStyleClasses = notificationToastStyleClassesArray.join(' ')

  return isMobile ? (
    <Snackbar ref={snackbar} />
  ) : (
    <div className={notificationToastStyleClasses}>
      <div className={styles.body} data-testid="notification-toast">
        {children && (
          <NoticeBanner
            type={type}
            handleOnClose={handleOnClose}
            styleOverrides={{ noticeBanner: styles.noticeBannerOverrides }}
            isDisplayed={isDisplayed}
          >
            {children}
          </NoticeBanner>
        )}
      </div>
    </div>
  )
}

export default NotificationToast
