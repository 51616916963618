import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { PaperBody1 } from '@/ui/Typography/Typography'

import { BannerText } from '@/generated/contentful/contentful'
import DisplayLink from '@/ui/Link/DisplayLink'

function linkTarget(href: string) {
  return href.includes(String(process.env.APP_DOMAIN)) ? '_self' : '_blank'
}

export function renderPencilText(document: BannerText) {
  const options: Options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (children ? <PaperBody1>{children}</PaperBody1> : null),
      [INLINES.HYPERLINK]: ({ data }, children) =>
        children && data?.uri ? (
          <DisplayLink id="pencil-text-link" color="secondary" href={data.uri} target={linkTarget(data.uri)}>
            {children}
          </DisplayLink>
        ) : null,
    },
    renderText: (text) => {
      if (!text) return
      return text
    },
  }

  return documentToReactComponents(document.json, options)
}
